import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

import { quizeDaysResponseType, Filter } from "common/Modal";
import { getTokenFromLocalCookie } from "common/CommonService";

export async function fetchParticipants(
	filter: Filter,
	options: { signal: AbortSignal }
): Promise<any> {
	const AuthStr = getTokenFromLocalCookie();
	const url: string = process.env.REACT_APP_BASE_URL + "api/partner/data/general/participants";
	try {
		const res = await axios.post(
			url,
			{ filter },
			{
				headers: {
					Authorization: "Bearer " + AuthStr,
				},
				signal: options.signal,
			}
		);
		return res.data;
	} catch (error: any) {
		if (axios.isCancel(error)) {
			console.log("Request canceled", error.message);
		} else {
			toast.error(error);
		}
		return [];
	}
}

export async function fetchEmails(filter: Filter, options: { signal: AbortSignal }): Promise<any> {
	const AuthStr = getTokenFromLocalCookie();
	const url: string = process.env.REACT_APP_BASE_URL + "api/partner/data/emails";
	try {
		const res = await axios.post(
			url,
			{ filter },
			{
				headers: {
					Authorization: "Bearer " + AuthStr,
				},
				signal: options.signal,
			}
		);
		return res.data;
	} catch (error: any) {
		if (axios.isCancel(error)) {
			console.log("Request canceled", error.message);
		} else {
			console.log(error);
		}
		return [];
	}
}

export async function fetchTasteTypes(
	filter: Filter,
	options: { signal: AbortSignal }
): Promise<any> {
	const AuthStr = getTokenFromLocalCookie();
	const url: string | undefined =
		process.env.REACT_APP_BASE_URL + "api/partner/data/general/tastetypes";
	try {
		const res = await axios.post(
			url,
			{ filter },
			{
				headers: {
					Authorization: "Bearer " + AuthStr,
				},
				signal: options.signal,
			}
		);
		if (res.data?.length > 0) {
			return _.orderBy(res.data, "count", "desc");
		} else {
			return [];
		}
	} catch (error: any) {
		if (axios.isCancel(error)) {
			console.log("Request canceled", error.message);
		} else {
			toast.error(error);
		}
		return [];
	}
}

export async function fetchWines(filter: Filter, options: { signal: AbortSignal }): Promise<any> {
	const AuthStr = getTokenFromLocalCookie();
	const url: string = process.env.REACT_APP_BASE_URL + "api/partner/data/general/topwines";
	try {
		const res = await axios.post(
			url,
			{ filter },
			{
				headers: {
					Authorization: "Bearer " + AuthStr,
				},
				signal: options.signal,
			}
		);
		return res.data.wines;
	} catch (error: any) {
		if (axios.isCancel(error)) {
			console.log("Request canceled", error.message);
		} else {
			toast.error(error);
		}
		return [];
	}
}

export async function fetchQuizDaysData(filter: Filter, options: { signal: AbortSignal }) {
	const AuthStr = getTokenFromLocalCookie();
	const url: string = process.env.REACT_APP_BASE_URL + "api/partner/data/quizdays";
	try {
		const res = await axios.post<quizeDaysResponseType[]>(
			url,
			{ filter },
			{
				headers: {
					Authorization: "Bearer " + AuthStr,
				},
				signal: options.signal,
			}
		);
		return res.data;
	} catch (error) {
		if (axios.isCancel(error)) {
			console.log("Request canceled", error.message);

			return [];
		} else {
			throw new Error("Error fetching quiz days data.");
		}
	}
}

export async function fetchV2UsageStatistics(
	filter: Filter,
	options: { signal: AbortSignal }
): Promise<any> {
	const AuthStr = getTokenFromLocalCookie();
	const url: string = process.env.REACT_APP_BASE_URL + "api/v2/partner/usage-statistics";
	try {
		const res = await axios.post<quizeDaysResponseType[]>(
			url,
			{ filter },
			{
				headers: {
					Authorization: "Bearer " + AuthStr,
				},
				signal: options.signal,
			}
		);
		return res.data;
	} catch (error) {
		if (axios.isCancel(error)) {
			console.log("Request canceled", error.message);
		} else {
			throw new Error("Error fetching v2 usage data.");
		}
	}
}
