import * as React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import DashboardModules from "./dashboard-modules";
import DateRangeSelection from "./subModules/date-range-picker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTokenFromLocalCookie } from "common/CommonService";

export default function DashboardPage() {
  const [restaurants, setRestaurants] = React.useState([]);
  const [selectedRestaurant, setSelectedRestaurant]: any = React.useState(null);
  const [quizzes, setQuizzes]: any = React.useState([]);
  const [selectedQuizzes, setSelectedQuizzes]: any = React.useState(null);
  const [filter, setFilter]: any = React.useState({});
  let type = "placeholder";

  //check if token exist or else redirect to login page.
  async function getToken() {
    const cookieToken = await getTokenFromLocalCookie();
    if (!cookieToken) window.location.href = "/auth/login";
    filter.token = cookieToken;
    setFilter(filter);
  }

  React.useEffect(() => {
    if (!filter.token) getToken();
  }, [filter]);

  React.useEffect(() => {
    getAllResturantData();
  }, []);

  //Get all restaurants data
  const getAllResturantData = () => {
    const AuthStr = getTokenFromLocalCookie();
    const AuthToken = "Bearer " + AuthStr;
    const url: string = process.env.REACT_APP_BASE_URL + "api/partner/data/restaurants";
    try {
      axios
        .get(url, {
          headers: { Authorization: AuthToken },
        })
        .then(function (res: any) {
          // handle success
          let response: any = res.data;
          const quizzes = [
            { quiz: { id: 0, name: "Alle Quizzes", quiz: [] } },
            ...response[0].quizzes,
          ];
          localStorage.setItem("AllRestaurants", JSON.stringify(response));
          const allQuizes: any = [];
          response.forEach((restaurant: any) => {
            restaurant.quizzes.forEach((quize: any) => {
              allQuizes.push(quize.quiz);
            });
          });
          localStorage.setItem("AllQuizes", JSON.stringify(allQuizes));
          setQuizzes(quizzes);
          setSelectedQuizzes(quizzes[0].quiz.id);
          const selectionPlaceholder: any = {
            restaurant: {
              id: 0,
              name: "Alle Standorte",
              token: AuthStr,
              quiz: response[0].quizzes[0],
              restaurants: response,
            },
          };
          const restaurants: any = [];
          if (type === "placeholder") {
            restaurants.push(selectionPlaceholder);
          }

          for (let i: number = 0; i < response.length; i++) {
            const restaurant = response[i];
            restaurant["token"] = AuthStr;
            restaurants.push({
              restaurant: restaurant,
              token: AuthStr,
            });
          }

          setSelectedRestaurant(restaurants[0].restaurant.id);
          setRestaurants(restaurants);
          filter.restaurants = response;
          filter.quizzes = [];
          setFilter(filter);
        });
    } catch (error: any) {
      toast.error(error);
    }
  };

  //On restauranct change set data to state and re-render updated data.
  const changeRestaurant = (restaurantId: number) => {
    const allRestaurants = JSON.parse(
      localStorage.getItem("AllRestaurants") || "[]"
    );

    const allQuizes = JSON.parse(localStorage.getItem("AllQuizes") || "[]");

    if (restaurantId === 0) {
      filter.restaurants = allRestaurants;
    } else {
      filter.restaurants = allRestaurants.filter(
        (x: any) => x.id === restaurantId
      );
      if (filter?.restaurants?.length && filter.restaurants[0]?.token) {
        filter.token = filter.restaurants[0]?.token;
      }
      const quizzes = [
        { quiz: { id: 0, name: "Alle Quizzes", quiz: [] } },
        ...filter.restaurants[0].quizzes,
      ];
      setQuizzes(quizzes);
    }

    updateFilter(filter);
  };

  //On quiz change set data to state and re-render updated data.
  function changeQuiz(selection: number) {
    const allQuizes = JSON.parse(localStorage.getItem("AllQuizes") || "[]");
    if (selection === 0) {
      filter.quizzes = [];
    } else {
      filter.quizzes = [allQuizes.find((x: any) => x.id === selection)].filter(
        Boolean
      );
    }
    updateFilter(filter);
  }

  const updateFilter = (newFilter: any) => {
    setFilter({ ...newFilter }); // done chnage
  };
  return (
    <>
      <ToastContainer />
      <div className="dflex headerInputMargins">
        <div className="pright">
          <Select
            sx={{
              width: 270,
              fontSize: "14px",
              marginBottom: 1,
              height: 40,
            }}
            value={selectedRestaurant}
            onChange={(e: any) => {
              setSelectedRestaurant(e.target.value);
              changeRestaurant(e.target.value);
            }}
            autoComplete="location"
            className=""
          >
            {restaurants.map((restaurant: any) => (
              <MenuItem
                key={restaurant.restaurant.id}
                value={restaurant.restaurant.id}
              >
                {restaurant.restaurant.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="pright">
          {(() => {
            if ({ selectedRestaurant }.selectedRestaurant === 0) {
              return null;
            } else {
              return (
                <Select
                  sx={{
                    width: 270,
                    fontSize: "14px",
                    marginBottom: 1,
                    height: 40,
                  }}
                  value={selectedQuizzes}
                  onChange={(e: any) => {
                    setSelectedQuizzes(e.target.value);
                    changeQuiz(e.target.value);
                  }}
                  autoComplete="location"
                  className=""
                >
                  {quizzes.map((n: any) => (
                    <MenuItem key={n.quiz.id} value={n.quiz.id}>
                      {n.quiz.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }
          })()}
        </div>
        <DateRangeSelection filter={filter} setFilter={updateFilter} />
      </div>
      <DashboardModules filter={filter} setFilter={updateFilter} />
    </>
  );
}
