import axios from "axios";
import { getTokenFromLocalCookie } from "common/CommonService";
import { useState, useEffect } from "react";
import * as echarts from "echarts";
import { useTranslate } from "@refinedev/core";
import { Filter,SalesDataResponse, ChartData} from "common/Modal";
  

const SalesData: React.FC<{ filter: Filter }> = ({ filter }) => {
    const [showChart, setShowChart] = useState<boolean>(false);
    const token = getTokenFromLocalCookie();
    const AuthStr = "Bearer " + token;
    const t = useTranslate();
    
  
  async function fetchSalesData(): Promise<void> {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/partner/data/sales`,
        {
          filter: filter,
        },
        {
          headers: {
            Authorization: AuthStr,
          },
        }
      );
     
      const response: SalesDataResponse[] = res.data;
      if (response) {
        const data: any = response;
        const months: string[] = 
        [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember",
        ];
        const chartData: ChartData = {
          data: [],
          months: [],
        };

        const minMonth: number = Math.min(
          ...data.map((o: any) => o.month)
        );

        const maxMonth: number = Math.max(
          ...data.map((o: any) => o.month)
        );

        for (let i = minMonth; i <= maxMonth; i++) {
          chartData.months.push(months[i - 1]);
        }

        for (let i = 0; i < data.length; i++) {
          const month:number = data[i].month;
          const sales = data[i].sales.toFixed(2);
          const location = data[i].name;

          if (chartData.data[location] === undefined) {
            chartData.data[location] = {
              name: location,
              sales: [],
            };
          }

          for (let j = minMonth; j <= maxMonth; j++) {
            let currentMonth = 0 + j;
            if (j > 9) {
              currentMonth = j;
            }
            if (month === currentMonth) {
              chartData.data[location].sales[j] = sales;
            } else if (!chartData.data[location].sales[j]) {
              chartData.data[location].sales[j] = 0;
            }
          }
        }

        chartData.data = Object.values(chartData.data);

        if (!chartData.data.length) {
          setShowChart(false);
          return;
        }
        if (chartData.data.length) setShowChart(true);

        const chartDom = document.getElementById("revenue-demo-chart");
        const existInstance = echarts.getInstanceByDom(chartDom!);
        if (existInstance) {
          if (true) {
            echarts.dispose(existInstance);
          }
        }

        const myChart = echarts.init(chartDom!);

        const series = chartData.data.map((location: any) => {
          return {
            name: location.name,
            data: location.sales.filter((sales: number) => sales !== null),
            type: "line",
          };
        });

        setTimeout(() => {
          let option: any;

          option = {
            legend: {
              data: series.map((location: any) => location.name),
            },
            tooltip: {
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              left: "30%",
              data: chartData.months.map((month: string) => month),
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: "{value} €",
                align: "center",
              },
            },
            series: series,
          };
          myChart.setOption(option);
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchSalesData();
  }, [filter]);

  if (!showChart) return null;
  return (
    <>
      <div className="mt-7">
        <p className="text-xl text-center sales-head-center"> {t('salesVolume')} </p>
        <div
          className=""
          style={{ height: "500px", width: "100%" }}
          id="revenue-demo-chart"
        ></div>
      </div>
    </>
  );
};

export default SalesData;
