import React, { useState, useContext } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";
import { FoodPairingWine } from "common/Modal";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
import { DashboardContext } from '../dashboard-context';

interface PopupState {
  isOpen: boolean;
  wineName: string;
}

export default function TopFoodPairingWines() {
  const { v2UsageStatistics } = useContext(DashboardContext) || { data: null };
  const t = useTranslate();

  const [popupState, setPopupState] = useState<PopupState>({
    isOpen: false,
    wineName: "",
  });

  const handlePopupOpen = (wineName: string) => {
    setPopupState({ isOpen: true, wineName });
  };

  const handlePopupClose = () => {
    setPopupState({ isOpen: false, wineName: "" });
  };

  if (v2UsageStatistics?.current != null){

  const topFoodPairingWinesType: FoodPairingWine[] = v2UsageStatistics.current.topFoodPairingWines;

  if (topFoodPairingWinesType && topFoodPairingWinesType.length > 0) {
    return (
      <>
        <ToastContainer />
        <p className="text-xl p-5" style={{ fontWeight: 600 }}>
          <span className="flex items-center">
            {t("topFoodPairingWine")}
            <ArrowUpwardIcon className="ml-1 mr-0.5 h-5 w-5 text-green-500" aria-hidden="true" />
          </span>
        </p>
        <ul className="-my-5 divide-y divide-gray-200">
          {topFoodPairingWinesType.map((wine: FoodPairingWine) => (
            <li key={wine.name}>
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0"></div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    <span
                      style={{ fontSize: "14px", margin: 0, marginRight: ".5rem" }}
                      className={"bg-green-100 text-green-800 inline-flex items-baseline px-1.5 py-0.5 rounded-full text-xs font-small md:mt-2 lg:mt-0"}
                    >
                      {wine.count}
                      {wine.price > 0 && wine.count > 1 && ` | ${(wine.price * wine.count).toFixed(2)}€`}
                    </span>
                    <b id="popup-create-div" onClick={() => handlePopupOpen(wine.name)}>{wine.name}</b>
                    {/* Popup Content */}
                    {popupState.isOpen && popupState.wineName === wine.name && (
                      <div id="popup-list-of-five-pairing-food">
                        <Typography>
                          {t("Was recommended to")}:
                        </Typography>
                        <List>
                          {wine.dishes.map((dish, index) => (
                            <ListItemText key={index}>
                              <span style={{marginRight:'10px'}}>
                              {dish.count}
                              </span>
                              <span style={{ fontSize: "14px", fontWeight: "600" }}>{dish.name}</span>
                            </ListItemText>
                          ))}
                        </List>
                        <button className="btn-close-modal" onClick={handlePopupClose}>{t("Close")}</button>
                      </div>
                    )}
                  </p>
                </div>
              </div>
              <div
                style={{
                  height: "0.5px",
                  backgroundColor: "#c5c5c5",
                  marginTop: "15px",
                }}
              ></div>
            </li>
          ))}
        </ul>
      </>
    );
  } else {
    return null;
  }
}else{
  return null;
}
}
