import { AuthBindings } from '@refinedev/core';
import { getTokenFromLocalCookie, setToken, unsetToken } from 'common/CommonService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const USER = 'auth-user';

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    const url: string = process.env.REACT_APP_BASE_URL + "api/auth/local";
    if (email && password) {
      await fetch(url, {
        // Adding method type
        method: 'POST',

        // Adding body or contents to send
        body: JSON.stringify({
          identifier: email,
          password: password,
        }),

        // Adding headers to the request
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => response.json())
        .then((responseData) => {
          if(responseData.user){
            const { jwt, user } = responseData;
            localStorage.setItem(USER, JSON.stringify(user));
            setToken(jwt)
            return {
              success: true,
              redirectTo: '/dashboard',
            };
          } else {
            toast.error("Wrong Credentials")
            // Toaster.success("succ")
          }
        })
        .catch((error) => {
          toast.error(error)
        });;
    }

    return {
      success: true,
      redirectTo: '/dashboard',
    };
  },
  logout: async () => {
    unsetToken()
    // localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const token = await getTokenFromLocalCookie()
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: '/login',
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = await getTokenFromLocalCookie()
    const user = JSON.parse(localStorage.getItem(USER)!);
    if (token) {
      return user;
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
