import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";
import { quizeDaysResponseType, Filter } from "../../common/Modal";
import { shiftDataArray } from "../../common/Common";
import { fetchQuizDaysData } from "../dashboard/dashboardService";

interface QuizDaysProps {
	filter: Filter;
}

const QuizDays: React.FC<QuizDaysProps> = ({ filter }) => {
	const [showChart, setShowChart] = useState(false);
	const t = useTranslate();

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;

		async function fetchData() {
			try {
				const response: quizeDaysResponseType[] = await fetchQuizDaysData(filter, {
					signal,
				});

				if (response.length === 0) {
					setShowChart(false);
					return;
				}

				const data = [0, 0, 0, 0, 0, 0, 0];
				const days = [
					"Montag",
					"Dienstag",
					"Mittwoch",
					"Donnerstag",
					"Freitag",
					"Samstag",
					"Sonntag",
				];

				for (let i = 0; i < response.length; i++) {
					const day = response[i].day;
					const count = response[i].count;
					data[day] = count;
				}
				shiftDataArray(data);

				const chartData = {
					data: data,
					days: days,
					name: "xyz",
				};

				setShowChart(true);

				setTimeout(() => {
					// Ensure the chart container exists
					const chartDom: HTMLElement = document.getElementById("quiz-days-chart")!;
					const myChart = echarts.init(chartDom);
					var option;

					option = {
						legend: {
							data: chartData.data,
						},
						tooltip: {
							trigger: "axis",
						},
						color: ["#621940"],
						xAxis: {
							type: "category",
							left: "30%",
							data: chartData.days,
						},
						yAxis: {
							type: "value",
							axisLabel: {
								formatter: "{value}",
								align: "center",
							},
						},
						series: [
							{
								name: t("quizzes"),
								type: "bar",
								data: chartData.data,
							},
						],
					};
					myChart.setOption(option);
				}, 0); // Use a timeout to ensure the DOM is updated
			} catch (error: any) {
				if (error.name !== "AbortError") {
					toast.error(error.message);
				}
			}
		}

		fetchData();

		return () => {
			abortController.abort();
		};
	}, [filter, t]); // Add t to the dependency array to ensure the translation is up to date

	return (
		<>
			<ToastContainer />
			{showChart && (
				<div className='margin-top-100'>
					<p className='text-xl' style={{ textAlign: "center" }}>
						{t("number_of_completed_quizzes_by_day_of_the_week")}
					</p>
					<div
						className=''
						style={{ height: "500px", width: "100%" }}
						id='quiz-days-chart'
					></div>
				</div>
			)}
		</>
	);
};

export default QuizDays;
