import React from "react";
import { Box, Grid, IconButton, Modal, Paper, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTranslate } from "@refinedev/core";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import calculateChange from "utils/calculateChange";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
	position: "relative",
}));

const modalStyle = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: ".5rem",
};

const StatisticsCard = (props: any) => {
	const {
		id,
		name,
		description,
		value,
		Icon,
		lg,
		xs,
		loading,
		prevValue,
		change,
		showInfo,
		infoTitle,
		infoDescription,
		onClose,
		openInfo,
	} = props;

	const t = useTranslate();

	// use change if in props or calculate from value and prevValue
	const calculatedChange = change ?? calculateChange(value, prevValue);

	// Don't render the card if value is null, undefined, or 0, and loading is false
	if (!loading && (value === null || value === undefined || value === 0)) {
		return null;
	}

	return (
		<Grid item lg={lg ?? 2} xs={xs ?? 6}>
			<Item>
				{loading ? (
					<div>
						<Skeleton
							variant='text'
							width={80}
							height={30}
							style={{
								marginBlockStart: "1.33em",
								marginBlockEnd: "1.33em",
								marginInlineStart: "0px",
								marginInlineEnd: "0px",
								marginTop: ".5rem",
							}}
							animation='wave'
						/>
						<Skeleton variant='text' width='60%' height={30} animation='wave' />
					</div>
				) : (
					<div>
						<h4
							style={{
								textAlign: "start",
								fontWeight: "normal",
								color: "#000",
								marginTop: ".5rem",
								fontSize: "16px",
								display: "flex",
								alignItems: "center",
							}}
						>
							{Icon && <Icon fontSize='small' style={{ marginRight: ".2rem" }} />}
							{name}
						</h4>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								padding: "0 0 0 3px",
							}}
							className='flex items-baseline text-2xl font-semibold text-bordeaux'
						>
							<div>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "22px",
									}}
									className='text-bordeaux'
								>
									{value}
								</span>
								{value && prevValue && prevValue !== "NaN" ? (
									<span className='ml-2 text-sm font-medium text-gray-500'>
										{" " + t("beforeHand")} {prevValue}
									</span>
								) : null}
							</div>
							{calculatedChange && (
								<Chip
									style={{
										display: "flex",
										justifyContent: "center",
									}}
									sx={
										calculatedChange >= "0%"
											? { bgcolor: "#D1F7DF", color: "#000" }
											: { bgcolor: "#F3D7D7", color: "#000" }
									}
									icon={
										calculatedChange >= "0%" ? (
											<ArrowUpwardIcon fontSize='small' color='success' />
										) : (
											<ArrowDownwardIcon fontSize='small' color='error' />
										)
									}
									label={
										<span
											style={{ fontWeight: "600" }}
											className={
												calculatedChange >= "0%"
													? "text-green-800"
													: "text-red-800"
											}
										>
											{calculatedChange}
										</span>
									}
								/>
							)}
						</div>
					</div>
				)}
				{infoTitle && infoDescription && (
					<IconButton
						aria-label='info'
						size='small'
						style={{ position: "absolute", top: 0, right: 0 }}
						onClick={() => openInfo(id)}
					>
						<InfoIcon style={{ color: "#82888a", opacity: 0.5 }} />
					</IconButton>
				)}
			</Item>
			<Modal
				open={showInfo === id}
				onClose={() => onClose()}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={modalStyle}>
					<Typography id='modal-modal-title' variant='h6' component='h2'>
						{infoTitle}
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						{infoDescription}
					</Typography>
					<IconButton
						aria-label='info'
						size='small'
						style={{ position: "absolute", top: 0, right: 0 }}
						onClick={() => onClose()}
					>
						<CloseIcon style={{ color: "black" }} />
					</IconButton>
				</Box>
			</Modal>
		</Grid>
	);
};

export default StatisticsCard;
