import React, { createContext, useEffect, useState, ReactNode, useRef } from "react";
import { CompletedQuizze, Filter, FoodPairingWine } from "common/Modal";

import {
	fetchParticipants,
	fetchEmails,
	fetchTasteTypes,
	fetchWines,
	fetchV2UsageStatistics,
} from "./dashboardService";

interface DashboardContextProps {
	data: any;
	loading: boolean;
	emails: any;
	tasteTypes: any;
	wines: any;
	v2UsageStatistics: v2UsageStatistics | null;
}

interface v2UsageStatistics {
	current: statistics;
	comparison: statistics;
}

interface statistics {
	chatbotOpenedCount: number;
	uniqueUsersCount: number;
	totalRecommendedWinesCount: number;
	clickThroughRate: {
		clickThroughRate: number;
		clickThroughRateRecommendation: number;
	};
	beginnerQuizStatistics: {
		completedQuizzesCount: number;
		startedQuizzesCount: number;
		averageWinePriceQuizSessions: string;
		quizDurationInSeconds: string;
		quizRating: string;
	};
	giftFinderStatistics: {
		totalUsers: number;
		totalCompletedQuizCount: number;
	};
	foodMatchingStatistics: {
		totalRequests: number;
		uniqueUsers: number;
	};
	freeTextInputs: {
		topicLabel: string;
		questionTextValGroups: [
			{
				questionTextVal: string;
				answerTextVals: [
					{
						count: number;
						answerTextVal: string;
					}
				];
			}
		];
	}[];
	topFoodPairingWines: FoodPairingWine[];
	completedQuizzes: CompletedQuizze[];
}

interface DashboardProviderProps {
	children: ReactNode;
	filter: Filter;
	setFilter: React.Dispatch<React.SetStateAction<Filter>>;
}

export const DashboardContext = createContext<DashboardContextProps | undefined>(undefined);

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
	children,
	filter,
	setFilter,
}) => {
	const [data, setData] = useState<any>(null);
	const [loading, setLoading]: any = useState(false);
	const [emails, setEmails] = useState([]);
	const [tasteTypes, setTasteTypes]: any = useState([]);
	const [wines, setWines]: any = useState([]);
	const [v2UsageStatistics, setV2UsageStatistics] = useState<any>(null);

	const [activeRequests, setActiveRequests] = useState(0);
	const abortControllersRef = useRef<AbortController[]>([]);

	useEffect(() => {
		const updateRequests = (change: number) => {
			setActiveRequests((prev) => {
				const newValue = prev + change;
				if (newValue === 0) {
					setLoading(false);
				} else {
					setLoading(true);
				}
				return newValue;
			});
		};

		const abortPreviousRequests = () => {
			abortControllersRef.current.forEach((controller) => controller.abort());
			abortControllersRef.current = [];
		};

		abortPreviousRequests();

		// Set loading to true when filter changes and reset all data
		setLoading(true);
		setData(null);
		setEmails([]);
		setTasteTypes([]);
		setWines([]);
		setV2UsageStatistics(null);

		if (filter?.restaurants?.length) {
			const abortController = new AbortController();
			abortControllersRef.current.push(abortController);
			updateRequests(1);
			fetchEmails(filter, { signal: abortController.signal })
				.then((fetchedEmails) => {
					setEmails(fetchedEmails);
					updateRequests(-1);
				})
				.catch(() => updateRequests(-1));
		}

		if (filter.restaurants?.length) {
			const abortController = new AbortController();
			abortControllersRef.current.push(abortController);
			updateRequests(1);
			fetchTasteTypes(filter, { signal: abortController.signal })
				.then((tasteTypesData) => {
					setTasteTypes(tasteTypesData);
					updateRequests(-1);
				})
				.catch(() => updateRequests(-1));
		}

		if (filter?.restaurants?.length) {
			const abortController = new AbortController();
			abortControllersRef.current.push(abortController);
			updateRequests(1);
			fetchWines(filter, { signal: abortController.signal })
				.then((winesData) => {
					calcTopWines(winesData);
					updateRequests(-1);
				})
				.catch(() => updateRequests(-1));
		}

		const abortController = new AbortController();
		abortControllersRef.current.push(abortController);
		updateRequests(1);
		fetchV2UsageStatistics(filter, { signal: abortController.signal })
			.then((usageStatistics) => {
				setV2UsageStatistics(usageStatistics);
				updateRequests(-1);
			})
			.catch(() => updateRequests(-1));
	}, [JSON.stringify(filter)]); // Hinweis: Abhängigkeiten sollten überprüft werden

	function calcTopWines(winesData: any) {
		winesData.sort((a: any, b: any) => {
			return b.count - a.count || b.count * b.price - a.count * a.price;
		});

		return setWines(winesData);
	}

	return (
		<DashboardContext.Provider
			value={{ data, loading, emails, tasteTypes, wines, v2UsageStatistics }}
		>
			{children}
		</DashboardContext.Provider>
	);
};
