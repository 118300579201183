const calculateChange = (value: any, prevValue: any) => {

    // try to formate to number
    value = parseFloat(value);
    prevValue = parseFloat(prevValue);

	return value && prevValue ? (((value - prevValue) / prevValue) * 100).toFixed(2) + " %" : null;
};

export default calculateChange;
