import { useState, useContext } from "react";
import { Box, Grid } from "@mui/material";
import AddsClickIcon from "@mui/icons-material/AdsClick";
import AssistantIcon from "@mui/icons-material/Assistant";
import WineBar from "@mui/icons-material/WineBar";
import Redeem from "@mui/icons-material/Redeem";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslate } from "@refinedev/core";
import { DashboardContext } from "../dashboard-context";
import StatisticsCard from "components/statistics-card";
import { AlarmOn, AttachMoney, Checklist, DinnerDining, Person, Quiz } from "@mui/icons-material";
import calculateChange from "utils/calculateChange";

export default function GeneralStats() {
	const { loading } = useContext(DashboardContext) || { loading: null };
	const { v2UsageStatistics } = useContext(DashboardContext) || {};
	const t = useTranslate();

	const [openedInfotextId, setOpenedInfotextId] = useState<number | null>(null);

	const helperTexts = [
		{
			title: "CTR",
			description: t(
				"Die CTR (Click-Through-Rate) misst den Prozentsatz der User, die den Chatbot geöffnet haben, einen Ablauf bis zur einer Weinempfehlung durchlaufen haben und schließlich die Empfehlung geöffnet haben."
			),
		},
		{
			title: "CTR " + t("of recommendations"),
			description: t(
				"Die CTR (Click-Through-Rate) der Empfehlungen misst den Prozentsatz der User, welche eine Weinempfehlung bekommen und geöffnet haben."
			),
		},
		{
			title: t("Chatbot opened"),
			description: t("Anzahl der User, die den Chatbot geöffnet haben."),
		},
		{
			title: t("Recommended Wines"),
			description: t("Anzahl der ausgespielten Weinempfehlungen."),
		},
	];

	const ctr = v2UsageStatistics?.current.clickThroughRate;
	const botOpenendCount = v2UsageStatistics?.current.chatbotOpenedCount ?? 0;
	let completedQuizzesPercentage = "";
	let comparisonCompletedQuizzesPercentage = "";

	if(v2UsageStatistics?.current.beginnerQuizStatistics.completedQuizzesCount && v2UsageStatistics?.current.beginnerQuizStatistics.startedQuizzesCount)
		completedQuizzesPercentage = ((v2UsageStatistics?.current.beginnerQuizStatistics.completedQuizzesCount / v2UsageStatistics?.current.beginnerQuizStatistics.startedQuizzesCount) * 100).toFixed(2) + " %";

	if(v2UsageStatistics?.comparison.beginnerQuizStatistics.completedQuizzesCount && v2UsageStatistics?.comparison.beginnerQuizStatistics.startedQuizzesCount)
		comparisonCompletedQuizzesPercentage = ((v2UsageStatistics?.comparison.beginnerQuizStatistics.completedQuizzesCount / v2UsageStatistics?.comparison.beginnerQuizStatistics.startedQuizzesCount) * 100).toFixed(2) + " %";

	return (
		<div>
			<ToastContainer />
			<h3
				style={{ margin: "-5px 0 0 0" }}
				className='text-lg leading-6 font-medium text-gray-900'
			>
				{t("overview", "overview")}
			</h3>
			<div className='loaderStyle' style={{ marginLeft: "45%", marginBottom: "2%" }}></div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container lg={12} spacing={0.5}>
					{v2UsageStatistics?.current.beginnerQuizStatistics.quizRating !== undefined || loading ? (
						<StatisticsCard
							id={"userRating"}
							value={(n => n !== "NaN" ? `${n} / 5 ⭐️` : "- / 5 ⭐️")(v2UsageStatistics?.current.beginnerQuizStatistics?.quizRating)}
							name={t("User Rating")}
							Icon={Quiz}
							lg={4}
							xs={6}
							loading={!v2UsageStatistics?.current.beginnerQuizStatistics.quizRating && loading}
							// prevValue={v2UsageStatistics?.comparison.beginnerQuizStatistics?.quizRating}
							// change={calculateChange(v2UsageStatistics?.current.beginnerQuizStatistics?.quizRating, v2UsageStatistics?.comparison.beginnerQuizStatistics?.quizRating)}
						/>
					) : null}
					{v2UsageStatistics?.current.beginnerQuizStatistics.startedQuizzesCount !== undefined || loading ? (
						<StatisticsCard
							id={"quizAttendees"}
							value={v2UsageStatistics?.current.beginnerQuizStatistics.startedQuizzesCount}
							name={t("Quiz Attendees")}
							Icon={Quiz}
							lg={4}
							xs={6}
							loading={!v2UsageStatistics?.current.beginnerQuizStatistics.startedQuizzesCount && loading}
							prevValue={v2UsageStatistics?.comparison.beginnerQuizStatistics?.startedQuizzesCount}
						/>
					) : null}
					{v2UsageStatistics?.current.beginnerQuizStatistics.completedQuizzesCount !== undefined || loading ? (
						<StatisticsCard
							id={"quizCompleted"}
							value={completedQuizzesPercentage}
							name={t("Quiz completed")}
							Icon={Checklist}
							lg={4}
							xs={6}
							loading={!v2UsageStatistics?.current.beginnerQuizStatistics.completedQuizzesCount && loading}
							prevValue={comparisonCompletedQuizzesPercentage}
							change={calculateChange(completedQuizzesPercentage, comparisonCompletedQuizzesPercentage)}
						/>
					) : null}
					{v2UsageStatistics?.current.beginnerQuizStatistics.quizDurationInSeconds !== undefined || loading ? (
						<StatisticsCard
							id={"quizDuration"}
							value={v2UsageStatistics?.current.beginnerQuizStatistics.quizDurationInSeconds + "s"}
							name={t("Quiz Duration")}
							Icon={AlarmOn}
							lg={4}
							xs={6}
							loading={!v2UsageStatistics?.current.beginnerQuizStatistics.quizDurationInSeconds && loading}
							prevValue={v2UsageStatistics?.comparison.beginnerQuizStatistics?.quizDurationInSeconds + "s"}
							change={calculateChange(v2UsageStatistics?.current.beginnerQuizStatistics?.quizDurationInSeconds, v2UsageStatistics?.comparison.beginnerQuizStatistics?.quizDurationInSeconds)}
						/>
					) : null}
					{v2UsageStatistics?.current.beginnerQuizStatistics.averageWinePriceQuizSessions !== undefined || loading ? (
						<StatisticsCard
							id={"winePrice"}
							value={v2UsageStatistics?.current.beginnerQuizStatistics.averageWinePriceQuizSessions + " €"}
							name={t("Wine Price")}
							Icon={AttachMoney}
							lg={4}
							xs={6}
							loading={!v2UsageStatistics?.current.beginnerQuizStatistics.averageWinePriceQuizSessions && loading}
							prevValue={v2UsageStatistics?.comparison.beginnerQuizStatistics?.averageWinePriceQuizSessions + " €"}
							change={calculateChange(v2UsageStatistics?.current.beginnerQuizStatistics?.averageWinePriceQuizSessions, v2UsageStatistics?.comparison.beginnerQuizStatistics?.averageWinePriceQuizSessions)}
						/>
					) : null}
					{(ctr?.clickThroughRate || loading) && (
						<StatisticsCard
							id={"CTR"}
							value={ctr?.clickThroughRate?.toFixed(2) + " %"}
							name='CTR'
							Icon={AddsClickIcon}
							loading={!ctr?.clickThroughRate && loading}
							lg={4}
							xs={6}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							infoTitle={helperTexts[0].title}
							infoDescription={helperTexts[0].description}
						/>
					)}
					{(ctr?.clickThroughRateRecommendation || loading) && (
						<StatisticsCard
							id={"CTROfRecommendation"}
							value={ctr?.clickThroughRateRecommendation?.toFixed(2) + " %"}
							name={"CTR " + t("of recommendations")}
							Icon={AddsClickIcon}
							loading={!ctr?.clickThroughRateRecommendation && loading}
							lg={4}
							xs={6}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							infoTitle={helperTexts[1].title}
							infoDescription={helperTexts[1].description}
						/>
					)}
					{(botOpenendCount || loading) && (
						<StatisticsCard
							id={"chatbotOpened"}
							value={botOpenendCount}
							name={t("Chatbot opened")}
							Icon={AssistantIcon}
							loading={!botOpenendCount && loading}
							lg={4}
							xs={6}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							infoTitle={helperTexts[2].title}
							infoDescription={helperTexts[2].description}
						/>
					)}
					{v2UsageStatistics?.current.uniqueUsersCount !== undefined || loading ? (
						<StatisticsCard
							id={"uniqueUsers"}
							value={v2UsageStatistics?.current.uniqueUsersCount}
							name={t("Unique Users")}
							Icon={Person}
							loading={!v2UsageStatistics?.current.uniqueUsersCount && loading}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							prevValue={v2UsageStatistics?.comparison.uniqueUsersCount}
							lg={4}
							xs={6}
						/>
					) : null}
					{v2UsageStatistics?.current.totalRecommendedWinesCount !== undefined || loading ? (
						<StatisticsCard
							id={"recommendedWines"}
							value={v2UsageStatistics?.current.totalRecommendedWinesCount}
							name={t("Recommended Wines")}
							Icon={WineBar}
							lg={4}
							xs={6}
							loading={!v2UsageStatistics?.current.totalRecommendedWinesCount && loading}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							infoTitle={helperTexts[3].title}
							infoDescription={helperTexts[3].description}
							prevValue={v2UsageStatistics?.comparison.totalRecommendedWinesCount}
						/>
					) : null}
					{v2UsageStatistics?.current.giftFinderStatistics.totalUsers !== undefined || loading ? (
						<StatisticsCard
							id={"giftFinderUserCount"}
							value={v2UsageStatistics?.current.giftFinderStatistics.totalUsers}
							name={t("Gift Finder Usercount")}
							Icon={Redeem}
							lg={4}
							xs={6}
							loading={!v2UsageStatistics?.current.giftFinderStatistics.totalUsers && loading}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							prevValue={v2UsageStatistics?.comparison.giftFinderStatistics?.totalUsers}
						/>
					) : null}
					{v2UsageStatistics?.current.giftFinderStatistics.totalCompletedQuizCount !==
						undefined || loading ? (
						<StatisticsCard
							id={"completedGiftfinderQuiz"}
							value={v2UsageStatistics?.current.giftFinderStatistics.totalCompletedQuizCount}
							name={t("Completed Giftfinder Quiz")}
							Icon={Redeem}
							lg={4}
							xs={6}
							loading={
								!v2UsageStatistics?.current.giftFinderStatistics.totalCompletedQuizCount &&
								loading
							}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							prevValue={v2UsageStatistics?.comparison.giftFinderStatistics?.totalCompletedQuizCount}
						/>
					) : null}
					{v2UsageStatistics?.current.foodMatchingStatistics.totalRequests !== undefined ||
					loading ? (
						<StatisticsCard
							id={"FoodMatchingRequests"}
							value={v2UsageStatistics?.current.foodMatchingStatistics.totalRequests}
							name={t("Food Matching Requests")}
							Icon={DinnerDining}
							lg={4}
							xs={6}
							loading={
								!v2UsageStatistics?.current.foodMatchingStatistics.totalRequests && loading
							}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							prevValue={v2UsageStatistics?.comparison.foodMatchingStatistics?.totalRequests}
						/>
					) : null}
					{v2UsageStatistics?.current.foodMatchingStatistics.uniqueUsers !== undefined ||
					loading ? (
						<StatisticsCard
							id={"FoodMatchingUniqueUsers"}
							value={v2UsageStatistics?.current.foodMatchingStatistics.uniqueUsers}
							name={t("Food Matching Unique Users")}
							Icon={DinnerDining}
							lg={4}
							xs={6}
							loading={
								!v2UsageStatistics?.current.foodMatchingStatistics.uniqueUsers && loading
							}
							showInfo={openedInfotextId}
							onClose={() => setOpenedInfotextId(null)}
							openInfo={(id: number) => setOpenedInfotextId(id)}
							prevValue={v2UsageStatistics?.comparison.foodMatchingStatistics?.uniqueUsers}
						/>
					) : null}
				</Grid>
			</Box>
		</div>
	);
}
